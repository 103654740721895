<template>
  <div id="Message">
		<div class="w720 message-box">
			<div class="icon"><img src="data:image/svg+xml;base64,PHN2ZyBpZD0i57uE5Lu2XzIyXzEiIGRhdGEtbmFtZT0i57uE5Lu2IDIyIOKAkyAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4NC40OTQiIGhlaWdodD0iODQuNDk0IiB2aWV3Qm94PSIwIDAgODQuNDk0IDg0LjQ5NCI+CiAgPHBhdGggaWQ9Iui3r+W+hF82IiBkYXRhLW5hbWU9Iui3r+W+hCA2IiBkPSJNOTY2LjE2LDU1My4xNjdhMzYuMjEyLDM2LjIxMiwwLDEsMSwzNi4yMTItMzYuMjEyaDBhMzYuMjE0LDM2LjIxNCwwLDAsMS0zNi4yMTIsMzYuMjEybTAtNzguNDU5YTQyLjI0Nyw0Mi4yNDcsMCwxLDAsNDIuMjQ4LDQyLjI0NmgwYTQyLjI5NCw0Mi4yOTQsMCwwLDAtNDIuMjQ4LTQyLjI0NiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkyMy45MTQgLTQ3NC43MDgpIiBmaWxsPSIjMTBkNjAwIi8+CiAgPHBhdGggaWQ9Iui3r+W+hF83IiBkYXRhLW5hbWU9Iui3r+W+hCA3IiBkPSJNOTU4LjYxNSw1MjcuMDIxbC0xMS40NDYtMTEuNDQ2LTQuMjY3LDQuMjY3LDE1LjcxMywxNS43MTMsMzAuOC0zMC44LTQuMjY3LTQuMjY3WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkyMy45MTQgLTQ3NC43MDgpIiBmaWxsPSIjMTBkNjAwIi8+Cjwvc3ZnPgo="/></div>
			<div class="mt-20"></div>
			<div class="title">{{title}}</div>
			<div class="mt-20"></div>
			<div class="description" v-html="description"></div>
			<div class="mt-50"></div>
			<div class="action">
				<span class="button" @click="toPath" >{{L("返回")}}</span>
			</div>
		</div>
  </div>
</template>

<script>
export default {
  name: 'Message',
  components: {
    
  },
  data(){
    return {
			title:"",
			description:"<p>",
			backPath:"/Index",
    };
  },
  async mounted () {
		if(this.$route.params && this.$route.params.title){
			for(var key in this.$route.params){
				if(typeof this[key] != 'undefined'){
					this[key] = this.$route.params[key];
				}
			}
		} else{
			this.$root.toPage("/Index");
		}
  },
  methods: {
		toPath(){
			this.$root.toPage(this.backPath);
		}
  }
}
</script>

<style scoped lang="less">
.message-box{
	text-align: center;
	margin:0 auto;
	.icon{
		img{
			width:50px;
		}
	}
	.title{
		font-size:26px;
		font-family: 'Arvo', 'NotoSansCJKscB';
	}
	.description{
		color:#626262;
		font-size:16px;
		line-height: 1.2;
		p{
			line-height: 1.2;
		}
		
	}
	.action{
		font-size:18px;
		.button{
			// padding-top:3px;
			line-height: 29px;
			height:32px;
		}
		
	}
}
</style>